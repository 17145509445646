@use '@styles/variables' as *;

.options {
	display: flex;
	flex-wrap: wrap;
	gap: 10px 30px;

	> * {
		flex: 0 0 100%;

		@media screen and (min-width: $lg) {
      flex: 0 0 calc(50% - 30px);
		}
    
		@media screen and (min-width: $xxl) {
      flex: 0 0 calc((100% - 30px * 2) / 3);
		}
	}
}
