@use '@styles/variables' as *;

.wrapper {
	max-width: max-content;
	margin-left: auto;
	padding: 0 58px;

	a {
		text-decoration: none;
		font-size: 18px;
		font-weight: 500;
		line-height: 27px;
	}

	ul {
		position: relative;
		display: flex;
		justify-content: center;
		height: 53px;
		border: 1px solid $primary-color;
		background-color: rgba($primary-color, 0.125);

		li {
			display: flex;
			align-items: center;
			width: 53px;
			height: 53px;
      transition:  background-color .34s;

			a {
				display: block;
				width: 100%;
				height: 100%;
				text-align: center;
				line-height: 53px;
				color: $primary-color;
				cursor: pointer;
				-webkit-user-select: none; /* Safari */
				-ms-user-select: none; /* IE 10 and IE 11 */
				user-select: none; /* Standard syntax */
        transition: color .34s;
			}

			&:hover {
				background-color: rgba($primary-color, 0.75);
				a {
					color: $light-color;
				}
			}
		}

		:global {
			.previous {
				position: absolute;
				left: -5px;
				top: 50%;
				height: 53px;
				width: 53px;
				transform: translateX(-100%) translateY(-50%);
				border: 1px solid $primary-color;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.next {
				position: absolute;
				right: -5px;
				top: 50%;
				height: 53px;
				width: 53px;
				transform: translateX(100%) translateY(-50%);
				border: 1px solid $primary-color;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.selected {
				background-color: $primary-color!important;

				a {
					color: $light-color;
				}
			}

			.disabled {
				opacity: 0.5;
				pointer-events: none;
			}
		}
	}

	&[data-disabled='true']{
		opacity: 0;
		visibility: hidden;
		height: 0;
	}
}
