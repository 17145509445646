@use '@styles/variables' as *;

.header {
	:global {
		.container {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
			gap: 40px;
		}
	}
}

.list {
	margin-top: 42px;
	:global {
		.container {
			display: grid;
			grid-template-columns: repeat(12, 1fr);
			gap: 20px;
			padding-top: 34px;
		}
	}

	@media screen and (min-width: $md) {
		margin-top: 51px;

		:global {
			.container {
				grid-template-columns: repeat(24, 1fr);
				grid-template-rows: repeat(2, 1fr);

				> *{
					&:first-of-type{
						grid-column: span 16;
						grid-row: span 2;
					}
					grid-column: span 8;
				}
			}
		}
	}
}
