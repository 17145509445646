@use '@styles/variables' as *;

.list {
	display: flex;
	flex-wrap: wrap;
	gap: 20px 10px;
  margin-bottom: 64px;
}

.item {
	button {
		background-color: $light-color;
		padding: 10px 14px;
		min-width: 200px;
		border-radius: 20px;
		font-size: 16px;
		font-weight: 700;
		border: 2px solid #b4b4b4;
		color: #b4b4b4;
		cursor: pointer;
    transition: color .24s, border-color .24s;

    &:hover{
      color: rgba($primary-color, .75);
			border-color: rgba($primary-color, .75);
    }
	}

	&[data-current='true'] {
		button {
			color: $primary-color;
			border-color: $primary-color;
		}
	}
}
