.title {
	font-size: 16px;

	&:not(:first-of-type) {
		margin-top: 2em;
	}
}

.base {
	margin-top: 24px;
}

.list {
	padding-left: 1em;
	list-style-type: disc;
	font-size: 16px;
	margin-bottom: 32px;
}

.separator {
	margin: 32px 0;
}

.buttons {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 46px;
	gap: 24px;
}

.hidden{
	opacity: 0;
	visibility: hidden;
}