@use '@styles/variables' as *;

.wrapper {
	margin-top: 36px;

	:global {
		.container {
			> * {
				background-color: $light-color;
				padding: 39px 25px;
				border-radius: 12px;
				margin-bottom: 47px;

				@media screen and (min-width: $md) {
					padding: 39px 45px;
				}

				@media screen and (min-width: $lg) {
					padding: 39px 64px;
				}
			}
		}
	}
}
