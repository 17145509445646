.button {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	max-width: max-content;
	background-color: transparent;
	cursor: pointer;

	img {
		width: 36px;
		height: 36px;
		object-fit: cover;
		border-radius: 50%;
	}
}

.image {
	width: 100%;
	height: 266px;
	border-radius: 7px;
	overflow: hidden;
	margin-bottom: 32px;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
