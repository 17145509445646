@use './variables' as *;
@use './mixins' as *;

@font-face {
	font-display: swap;
	font-family: 'Normalidad Compact Var';
	font-style: normal;
	font-weight: 700;
	src: local('Normalidad Compact Var'), url('../fonts/NormalidadCompact-Bold.woff2');
	text-rendering: optimizeLegibility;
}

@font-face {
	font-display: swap;
	font-family: 'Normalidad Compact Var';
	font-style: normal;
	font-weight: 500;
	src: local('Normalidad Compact Var'), url('../fonts/NormalidadCompact-Medium.woff2');
	text-rendering: optimizeLegibility;
}

@font-face {
	font-display: swap;
	font-family: 'Normalidad Compact Var';
	font-style: normal;
	font-weight: 400;
	src: local('Normalidad Compact Var'), url('../fonts/NormalidadCompact-Regular.woff2');
	text-rendering: optimizeLegibility;
}

@font-face {
	font-display: swap;
	font-family: 'Normalidad Compact Var';
	font-style: normal;
	font-weight: 300;
	src: local('Normalidad Compact Var'), url('../fonts/NormalidadCompact-Light.woff2');
	text-rendering: optimizeLegibility;
}

*,
*:before,
*:after {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

body {
	box-sizing: border-box;
	font-family: 'Normalidad Compact Var', sans-serif;
	min-width: 300px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

:root {
	font-size: 10px;
}

a {
	text-decoration: none;
	color: inherit;
	color: $link-color;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	text-decoration-line: underline;
}

ul,
ol {
	list-style-type: none;
	list-style-position: inside;
}

button {
	border: none;
	outline: transparent;
}

textarea {
	@include roboto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

img.empty-image{
	object-fit: contain!important;
}