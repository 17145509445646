@use '@styles/variables' as *;

.wrapper {
	width: 100%;
	max-width: $md;
	margin-bottom: 56px;

	.label {
		display: block;
		color: $primary-color;
		font-size: 14px;
		font-weight: 700;
		line-height: 18.48px;
		margin-bottom: 7px;
	}

	.inline {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 44px 24px;

		.range {
			position: relative;
			display: flex;
			flex-direction: column;
			gap: 10px;
			width: 100%;
			flex: 1 0 250px;
			margin-bottom: -28.5px;

			&Input {
				:global {
					.range-slider__thumb {
						width: 16px;
						height: 16px;
						background-color: $primary-color;
					}

					.range-slider__thumb[data-lower] {
						width: 0;
					}

					.range-slider__range {
						border-radius: 7px;
						background-color: $primary-color;
					}
				}
			}

			&Description {
				display: flex;
				justify-content: space-between;
				min-width: max-content;
				gap: 20px;
				font-size: 14px;
				font-weight: 400;
				line-height: 18.5px;
				margin-top: 10px;
			}
		}

		.input {
			display: flex;
			align-items: center;
			margin-left: auto;
			gap: 5px;
			min-width: 114px;

			input {
				padding: 12px 16px;
			}

			&Scale {
				font-size: 14px;
				font-weight: 400;
				line-height: 18.48px;
			}
		}
	}

	.error{
		margin-top: 1.5em;
		color: $danger-color;
	}

	&[data-disabled='true']{
		opacity: .5;
		pointer-events: none;
	}
}
