.wrapper {
	.title {
		margin-bottom: 1em;
	}

	.formFields {
		display: flex;
		flex-wrap: wrap;
		gap: 0 30px;

		> * {
			flex: 1 0 300px;
		}
	}

	.submitBtn {
		display: block;
		margin-left: auto;
		width: 100%;
		max-width: 294px;
	}
}
