.wrapper {
	padding: 0 0 15px;
}

.title {
	color: #333;
	font-size: 12px;
	font-weight: 700;
    margin-bottom: 6px;
}

.item {
	display: flex;
	align-items: center;
	color: #f93701;
	font-size: 12px;
	font-weight: 400;

	&:not(:last-child) {
		margin-bottom: 4px;
	}

	&:before {
		content: url('data:image/svg+xml,<svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_594_157)"><path d="M4.5 0C2.01466 0 0 2.01471 0 4.50011C0 6.9853 2.01466 9 4.5 9C6.98534 9 9 6.9853 9 4.50011C9 2.01471 6.98535 0 4.5 0ZM5.72796 4.76678L6.21989 5.2585C6.35576 5.39438 6.4237 5.57253 6.4237 5.75067C6.4237 5.92859 6.35576 6.10674 6.21989 6.24262C5.94837 6.51415 5.50777 6.51415 5.23602 6.24262L4.74408 5.75067L4.47766 5.484L3.71906 6.24262C3.4473 6.51415 3.00693 6.51415 2.73519 6.24262C2.59931 6.10674 2.53138 5.92882 2.53138 5.75067C2.53138 5.57253 2.59954 5.39438 2.7354 5.2585L3.22712 4.76678L3.49378 4.50011L2.73519 3.7415C2.46343 3.46975 2.46343 3.02936 2.73519 2.75761C3.00693 2.48585 3.44753 2.48585 3.71906 2.75761L4.47766 3.51622L5.23602 2.75761C5.50777 2.48585 5.94837 2.48585 6.22011 2.75761C6.49164 3.02936 6.49164 3.46975 6.22011 3.7415L5.46152 4.50011L5.72796 4.76678Z" fill="%23E83A39"/></g><defs><clipPath id="clip0_594_157"><rect width="9" height="9" fill="white"/></clipPath></defs></svg>');
		display: block;
		margin-right: 5px;
	}

	&[data-is-valid='true'] {
		color: #4fb609;

		&:before {
			content: url('data:image/svg+xml,<svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_594_155)"><path d="M4.5 0C2.01465 0 0 2.0147 0 4.49989C0 6.9853 2.01465 9 4.5 9C6.98535 9 9 6.9853 9 4.49989C9 2.0147 6.98535 0 4.5 0ZM6.84527 3.74128L4.83636 5.75089L4.34421 6.24284C4.07245 6.51437 3.63208 6.51437 3.36034 6.24262L2.86841 5.75045L2.15473 5.03698C1.88321 4.76523 1.88321 4.32462 2.15495 4.05309C2.29083 3.91743 2.46875 3.84949 2.64667 3.84949C2.82481 3.84949 3.00295 3.91743 3.1386 4.05309L3.62854 4.54304C3.75202 4.66653 3.9523 4.66653 4.07577 4.54304L5.8614 2.75738C6.13291 2.48585 6.57351 2.48585 6.84504 2.75738L6.84527 2.7576C7.11679 3.02936 7.11701 3.46974 6.84527 3.74128Z" fill="%234FB609"/></g><defs><clipPath id="clip0_594_155"><rect width="9" height="9" fill="white"/></clipPath></defs></svg>');
		}
	}
}
