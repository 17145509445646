@use '@styles/variables' as *;
@use '@styles/mixins' as *;

.wrapper {
	.label {
		display: block;
		margin-bottom: 10px;
		color: $dark-color;
		font-size: 16px;
		font-weight: 400;
		line-height: 150%;
	}

	.field {
		position: relative;
		z-index: 10;

		&[data-has-info='true']{
			padding-right: 46px;
		}
	}

	.input {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		padding-right: 30px;
		background-color: $light-color;
		border: 1px solid rgba(211, 214, 228, 1);
		cursor: pointer;

		&Text {
			display: -webkit-box;
			text-overflow: ellipsis;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			overflow: hidden;
			width: 100%;
			font-size: 16px;
			line-height: 24px;
			padding-left: 30px;
			padding: 16px 30px 0;
			margin-bottom: 16px;
		}

		&Image {
			min-width: 140px;
			width: 140px;
			height: 100%;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
				object-position: center;
			}
		}

		&[data-opend='true'] {
			+ .optionsList {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	.optionsList {
		position: absolute;
		z-index: -1;
		bottom: 0;
		left: 0;
		display: block;
		width: 100%;
		padding-top: 17px;
		background-color: $light-color;
		transform: translateY(calc(100% - 17px));
		box-shadow: 0px 10px 49px 0px #00000021;
		border-radius: 0 0 7px 7px;
		overflow: hidden;

		opacity: 0;
		visibility: hidden;

		&Wrapper {
			overflow-x: hidden;
			overflow-y: auto;
			max-height: 200px;
			@include scrollbar;
		}
	}

	.option {
		width: 100%;
		padding: 10px 10px 10px 42px;
		background-color: $light-color;
		cursor: pointer;
		transition: background-color 0.34s;

		&:hover {
			background-color: #c3c3c31f;
		}

		span {
			font-size: 16px;
			font-weight: 500;
			line-height: 24px;
		}

		&[data-default='true'] {
			opacity: 0.65;
			pointer-events: none;
			background-color: #c3c3c31f;
		}

		&[data-current='true'] {
			background-color: #c3c3c31f;
		}
	}

	.error {
		position: relative;
		z-index: 0;
		display: block;
		margin-top: 5px;
		margin-bottom: 10px;
		color: $danger-color;
		font-size: 12px;
		font-weight: 400;
		min-height: 15px;
		opacity: 0;
		transition: opacity 0.24s;
	}

	&[data-error='true'] {
		.input {
			border: 1.5px solid $danger-color;
		}

		.error {
			opacity: 1;
		}
	}

	&Multi {
		.option {
			display: flex;
			align-items: center;
			gap: 20px;
			padding-left: 32px;

			&::before {
				content: '';
				display: block;
				width: 19px;
				height: 19px;
				border: 2px solid rgba(225, 225, 225, 1);
				border-radius: 3px;
				box-shadow: inset 0 0 0 2px $light-color;
				transition: background-color 0.34s;
			}

			&[data-current='true'] {
				&::before {
					background-color: $primary-color;
				}
			}

			&[data-default='true'] {
				&::before {
					display: none;
				}
			}
		}
	}
}
