.wrapper {
	text-align: center;
	padding: 60px 0;
	.title {
		margin: 2em 0;
	}

	.mainList {
		font-size: 16px;
		text-align: left;
		list-style-type: upper-roman;

		li {
			margin-bottom: 1em;
		}

		> li {
			font-weight: bold;

			ol {
				font-weight: normal;
				list-style-type: decimal;
				padding-left: 30px;
				margin-top: 1em;
			}
		}
	}

	.form {
    display: flex;
    align-items: center;
    gap: 32px;
    flex-wrap: wrap-reverse;
		margin: 60px auto 0;
	}
}
