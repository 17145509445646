@use '@styles/variables' as *;

.wrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 23px;
	margin-top: 36px;
	border-top: 1px solid #d3d6e4;
	padding-top: 24px;

	.title {
		color: $primary-color;
		font-size: 16px;
		font-weight: 700;
		line-height: 21.12px;
	}

	.summary {
		display: flex;
    flex: 2 0;
		.text {
      min-width: 150px;
			font-size: 16px;
			font-weight: 400;

			&:first-of-type {
				padding-right: 22px;
				border-right: 1px solid #d3d6e4;
			}
			&:last-of-type {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
				padding-left: 22px;
			}
		}
	}

	.buttons {
		display: flex;
    align-items: center;
		flex-wrap: wrap;
		gap: 23px;
		margin-left: auto;
	}
}
