@use '@styles/variables' as *;

.wrapper {
	background-color: $light-color;

	:global {
		.container {
			display: flex;
			align-items: center;
			justify-content: center;
			min-height: 100vh;
			height: max-content;
			width: 100%;
		}
	}
}

.formWrapper {
	background-color: $light-color;
	padding: 52px 0;
	width: 100%;

	img {
		display: block;
		width: 100%;
		max-width: 253px;
		margin: 0 auto;
	}

	.title {
		margin-top: 44px;
		margin-bottom: 55px;
	}

	.submitBtn {
		margin-top: 32px;
	}

	@media screen and (min-width: $sm) {
		position: relative;
		z-index: 0;
		max-width: $sm;
		padding: 52px 40px 60px;
		border: 1px solid #d3d6e4;
		box-shadow: 0px 10px 20px 0px #1414331a;

		&:before {
			content: '';
			position: absolute;
			display: block;
			z-index: -2;
			width: 600px;
			height: 600px;
			left: 0;
			bottom: 0;
			background-repeat: no-repeat;
			background-position: center center;
			background-image: url('../../assets/images/logo-bg.png');
			transform: translate(-55%, 30%);
		}

		&:after {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			z-index: -1;
			background-color: $light-color;
		}

		.title {
			margin-bottom: 40px;
		}
	}
}

.fieldsWrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 0 30px;

	> * {
		flex: 1 0 300px;
	}
}

.buttons {
	@media screen and (min-width: $md) {
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		justify-content: flex-start;
		gap: 26px;

		> * {
			max-width: 294px;
		}
	}
}


.backToLogin{
	margin: 32px auto 0;
	max-width: max-content;
}