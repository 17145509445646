@use '@styles/variables' as *;

.loader {
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba($gray-color, 0.5);
}

.wrapper {
	*[data-current-tab='true'] {
		opacity: 1;
		visibility: visible;
	}
	*[data-current-tab='false'] {
		opacity: 0;
		visibility: hidden;
		height: 0;
	}
}

.configurator {
	padding: 32px;
	background-color: $light-color;
	border-radius: 7px;
}

.modalText {
	margin-bottom: 1em;
}

.buttons {
	display: flex;
	gap: 24px;
	justify-content: flex-end;
	margin-top: 38px;
}

.title {
	margin-bottom: 1.5em !important;
}
